import { LOCATION_CHANGE } from 'react-router-redux';
import { isServerSideRendering } from '@dbh/environment';
import { Map } from 'immutable';
import { PIXELS } from '@dbh/daybreak-hero-redux';
import _noop from 'lodash/noop';
import { getIsDbhStaff } from '@dbh/dom';
import invariant from 'invariant';
import 'prop-types';
import '@dbh/generic-types';
import throwInServerSideRendering from '@dbh/throw-in-server-side-rendering';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import { createUseBoundEventCreator, useBrowserOnlyInjectReducer } from '@dbh/redux-extra';
import '@dbh/booking-types';
import { createSelector } from 'reselect';
import { makeSelectCurrentCountry } from '@dbh/countries-redux';
import { makeSelectSearchContext, makeSelectTemplateCode, makeSelectWebpagesLoaded, makeSelectCurrentWebpageRouteTemplateCode } from '@dbh/webpages-redux';
import { createSimpleBooleanSelector, useMakeSelect } from '@dbh/reselect-extra';
import { makeSelectCurrentGuidBooking } from '@dbh/bookings-redux';
import { useMemo, useEffect } from 'react';
import { PAGE_TYPES } from '@dbh/admindev-constants';
import { makeSelectCountryAndLocale } from '@dbh/routing-redux';

const GTAG_SPEED_SAMPLE_RATE=100;const REDUX_AND_SAGA_KEY="trackingScriptsManager";const CONVERSION_PIXELS_CAN_BE_FIRED="@dbh/tracking-scripts-redux/CONVERSION_PIXELS_CAN_BE_FIRED";const CAN_FIRE_PAGE_VIEW_TRACKING_EVENT="@dbh/tracking-scripts-redux/CAN_FIRE_PAGE_VIEW_TRACKING_EVENT";const PIXELS_FIRING_FUNCTIONS_CALLED="@dbh/tracking-scripts-redux/PIXELS_FIRING_FUNCTIONS_CALLED";const TRACKING_SCRIPTS_MANAGER_DISABLED="@dbh/tracking-scripts-redux/TRACKING_SCRIPTS_MANAGER_DISABLED";const TRACKING_SCRIPTS_INJECTED="@dbh/tracking-scripts-redux/TRACKING_SCRIPTS_INJECTED";const DATA_LAYER_EVENTS={PAGE_VIEW:"dbh.page_view",LOCALE_CHANGED:"dbh.locale_changed",COUNTRY_CHANGED:"dbh.country_changed"};// If this is true we include the `TrackingScriptsManager` container (including sagas)
// even for detected bots (`Google`, `Yahoo`, `Yandex`, `Botify`).
const TRACKING_SCRIPTS_MANAGER_ACTIVE_FOR_BOTS=!0;const initialState=Map({isTrackingScriptsManagerEnabled:!0,isTrackingScriptsInjected:!1,pixelFiringInvocations:Map({})});const AWIN_ADVERTISER_ID="16049";const PIXEL_GENERIC_EVENT_TYPES=Object.freeze({SEARCH:"SEARCH",PAGE_VIEW:"PAGE_VIEW",CONVERSION:"CONVERSION",ADD_TO_CART:"ADD_TO_CART"});// These pixels are depending on `gtag` script.
const GTAG_RELATED_PIXELS=[PIXELS.ADWORDS,PIXELS.GOOGLE_ANALYTICS,PIXELS.GOOGLE_ENHANCED_COMMERCE];// When we want `hotjar` to show sensitive privacy fields without asterisks (i.e credit card number)
// in the dashboard, we have to print `data-hj-suppress=true`. Otherwise we have to print
// `data-hj-whitelist=true`, to be sure that `hotjar` does not choose by itself to obfuscate the
// field with asterisks in the dashboard. These props are exclusive and can only be passed as
// `true`, `false` has no effect.
// The same for `Microsoft Clarity`.
// @see {@link https://learn.microsoft.com/en-us/clarity/setup-and-installation/clarity-api#html-apis}
// @see {@link https://help.hotjar.com/hc/en-us/articles/115012439167-How-to-Suppress-Text-Images-and-User-Input-from-Collected-Data}
// @see {@link https://help.hotjar.com/hc/en-us/articles/115015563287-How-to-Show-Elements-and-Keystrokes-in-Data-Collection}
const RECORDING_HTML_MASK_ATTRIBUTES={"data-hj-suppress":!0,"data-clarity-mask":!0};const RECORDING_HTML_UN_MASK_ATTRIBUTES={"data-hj-allow":!0,"data-clarity-unmask":!0};

/*
 *
 * `buildReducerKeyPixelFiringInvocation`: `@dbh/tracking-scripts-redux`.
 *
 */ /**
 * Do not put the validator here, the reducer is not supposed to throw.
 * @param {Object} options .
 * @return {Array} Use with `getIn` or `setIn` in the reducer and selectors.
 * @see `./validators#validateOptionsPixelFiringFunctionsCalled`.
 */const buildReducerKeyPixelFiringInvocation=a=>{if(a){const{pixelGenericEventType:b}=a;return ["pixelFiringInvocations",b]}};var buildReducerKeyPixelFiringInvocation$1 = buildReducerKeyPixelFiringInvocation;

const trackingScriptsManagerReducer=function(a,b){if(void 0===a&&(a=initialState),isServerSideRendering()){// We check for the presence of `dbh_testonly_disable_pixels=true`
// parameter, if present - we disable the pixels injection by
// setting `isTrackingScriptsManagerEnabled` to `true`.
if(b.type===LOCATION_CHANGE){const{payload:c}=b,d=c&&c.search,e=a.get("isTrackingScriptsManagerEnabled");// `isTrackingScriptsManagerEnabled` is only a sanity check, because it should
// never be true in SSR, as `trackingScriptsManager` component is only
// for the browser.
if(e&&-1!==d.indexOf("dbh_testonly_disable_pixels=true"))return a.set("isTrackingScriptsManagerEnabled",!1)}// We don't want this reducer to do anything else in SSR.
return a}const{type:c,payload:d}=b;switch(c){case TRACKING_SCRIPTS_INJECTED:return a.set("isTrackingScriptsInjected",!0);case TRACKING_SCRIPTS_MANAGER_DISABLED:return a.set("isTrackingScriptsManagerEnabled",!1);case PIXELS_FIRING_FUNCTIONS_CALLED:{const{idUnique:b}=d,c=buildReducerKeyPixelFiringInvocation$1(d);return a.setIn(c,b)}default:return a}};var reducer = trackingScriptsManagerReducer;

/**
 * Returns log and error functions that always log with the tracking scripts
 * manager prefix.
 * @typedef {{log: Function, error: Function}} Loggers
 * @return {Loggers} .
 */const createLoggers=()=>{const a=getIsDbhStaff(),b=!a?_noop:console.log.bind(console,"[DBH][TRACKING]"),c=!a?_noop:console.error.bind(console,"[DBH][TRACKING][ERROR]");/* eslint-disable no-console */ /* eslint-enable no-console */return {log:b,error:c}};var createLoggers$1 = createLoggers;

const DEFAULT_DOM_REMOVAL_TIMEOUT=500,DOM_ID_PREFIX="dbh-pixel-";let counter=0;/**
 * Inserts an image with `src` in the `DOM`, waits a little so that it can
 * hopefully load, then removes it from the `DOM`. Useful for loading pixels that
 * only have to be loaded on certain pageTypes or country/culture pairs: you can't
 * "leave" them in the DOM, you have to load them, then remove them right after.
 * @param {string} src The source of the image.
 * @param {number} timeoutMsParam After how many milliseconds the image has to be
 * removed from the `DOM`.
 * @return {string} The `DOM` id of the image added to the `DOM`. Do not rely
 * on this existing in the `DOM`, it is temporary unless `timeoutMs` is zero.
 */const loadPixelAndRemoveItAfter=withConformsTo("loadPixelAndRemoveItAfter",[],(a,b)=>{throwInServerSideRendering("loadPixelAndRemoveItAfter");const c=b||DEFAULT_DOM_REMOVAL_TIMEOUT,d=""+DOM_ID_PREFIX+counter;invariant(!document.getElementById(d),"(`loadPixelAndRemoveItAfter`) Cannot add pixel with id: `"+d+"` to the `DOM`, it already exists."),counter+=1;const e=document.createElement("img");e.height="1",e.width="1",e.style["border-style"]="none",e.style.display="none",e.alt="",e.src=a,e.setAttribute("id",d);const f=document.body.appendChild(e);return 0<c&&setTimeout(()=>document.body.removeChild(f),c),d});var loadPixelAndRemoveItAfter$1 = loadPixelAndRemoveItAfter;

/**
 * Returns the `HTML` attributes for privacy-sensitive elements.
 * When we want `hotjar` or `Microsoft Clarity` to show sensitive privacy fields
 * without asterisks (i.e credit card number) in the dashboard, we have to print
 * `data-hj-suppress=true`, `data-clarity-mask=true`. Otherwise we have to print
 * `data-hj-whitelist=true`, `data-clarity-mask=true`, to be sure that `hotjar`
 * and `Microsoft Clarity` does not choose by itself to obfuscate the field with
 * asterisks in the dashboard. These props are exclusive and can only be passed
 * as `true`, `false` has no effect.
 * @see {@link https://learn.microsoft.com/en-us/clarity/setup-and-installation/clarity-api#html-apis}
 * @see {@link https://help.hotjar.com/hc/en-us/articles/115012439167-How-to-Suppress-Text-Images-and-User-Input-from-Collected-Data}
 * @see {@link https://help.hotjar.com/hc/en-us/articles/115015563287-How-to-Show-Elements-and-Keystrokes-in-Data-Collection}
 * @param {boolean} privacySensitive .
 * @return {Object?} .
 */const getHtmlPrivacySensitiveAttributes=withConformsTo("getHtmlPrivacySensitiveAttributes",[],a=>"boolean"==typeof a?a?RECORDING_HTML_MASK_ATTRIBUTES:RECORDING_HTML_UN_MASK_ATTRIBUTES:void 0);var getHtmlPrivacySensitiveAttributes$1 = getHtmlPrivacySensitiveAttributes;

const optionsPixelFiringInvokedForPropType=_noop;

const canFirePageViewTrackingEvent=a=>{let{templateCode:b}=a;return {type:CAN_FIRE_PAGE_VIEW_TRACKING_EVENT,payload:{templateCode:b}}};const useCanFirePageViewEvent=createUseBoundEventCreator(canFirePageViewTrackingEvent);const trackingScriptsManagerDisabled=()=>({type:TRACKING_SCRIPTS_MANAGER_DISABLED});const useTrackingScriptsManagerDisabled=createUseBoundEventCreator(trackingScriptsManagerDisabled);/**
 * Indicates that somewhere in the code, we have called a function or generator
 * function that is supposed to fire a pixel (by calling an `API` or inserting
 * an image in the `DOM`), if it succeeds. This event being dispatched does not
 * mean that the called "firing" method has either completed nor succeeded, it
 * was simply called by us.
 * @param {Object} options .
 * @param {string} options.pixelGenericEventType .
 * @param {string} options.idUnique .
 * @return {Object} .
 */const pixelsFiringFunctionsCalled=withConformsTo("pixelsFiringFunctionsCalled",["options",optionsPixelFiringInvokedForPropType],a=>{const{pixelGenericEventType:b,idUnique:c}=a;return {type:PIXELS_FIRING_FUNCTIONS_CALLED,payload:{pixelGenericEventType:b,idUnique:c}}});/**
 * Indicates that somewhere in the code, we have called functions or generator
 * functions that are supposed to fire one or more conversion pixels.
 * @param {string} guidBooking The unique identifier of the current booking.
 * @return {Object} .
 */const pixelsFiringFunctionsCalledForConversion=withConformsTo("pixelsFiringFunctionsCalledForConversion",[],a=>pixelsFiringFunctionsCalled({idUnique:a,pixelGenericEventType:PIXEL_GENERIC_EVENT_TYPES.CONVERSION}));/**
 * Dispatched when the "conversion" pixels to be fired for the given booking.
 * @param {Immutable.Map} booking The booking `API` response.
 * @return {Object} .
 */const conversionPixelsCanBeFired=withConformsTo("conversionPixelsCanBeFired",[],a=>({type:CONVERSION_PIXELS_CAN_BE_FIRED,payload:{booking:a}}));const trackingScriptsInjected=()=>({type:TRACKING_SCRIPTS_INJECTED});

/**
 * Returns a selector that returns the data needed for any Facebook event
 * with `content_type: hotel`.
 * @return {Object} The data in Facebook pixel format.
 */const makeSelectHotelTypeData=()=>createSelector(makeSelectSearchContext(),makeSelectCurrentCountry(),(a,b)=>{const c=b&&b.get("name"),d=a&&a.get("searchDate");// May be wrong for the `near` or `address` search in case a place near the
// region limit is searched. Wrong for the `hotel` search.
return {country:c,checkin_date:d,checkout_date:d}});var makeSelectHotelTypeData$1 = makeSelectHotelTypeData;

const selectTrackingScriptsManagerDomain=()=>a=>a.get(REDUX_AND_SAGA_KEY);var selectTrackingScriptsManagerDomain$1 = selectTrackingScriptsManagerDomain;

const makeSelectIsTrackingScriptsManagerEnabled=()=>createSimpleBooleanSelector(selectTrackingScriptsManagerDomain$1(),["isTrackingScriptsManagerEnabled"]);var makeSelectIsTrackingScriptsManagerEnabled$1 = makeSelectIsTrackingScriptsManagerEnabled;

/**
 * Returns a selector that returns, given the options, if the pixels firing
 * functions for a certain generic event (like "conversion") were already called.
 * @param {Object} options .
 * @return {boolean} .
 */const makeSelectWasPixelFiringFunctionCalled=withConformsTo("makeSelectWasPixelFiringFunctionCalled",[],a=>{const{idUnique:b}=a,c=buildReducerKeyPixelFiringInvocation$1(a);return createSelector(selectTrackingScriptsManagerDomain$1(),a=>{if(a){const d=a.getIn(c);return d===b}})});var makeSelectWasPixelFiringFunctionCalled$1 = makeSelectWasPixelFiringFunctionCalled;

/**
 * Returns a selector that returns, given the "current booking" taken from the
 * store, if the functions that fire the conversion pixels have already been
 * called for it. We don't want to send conversion pixels multiple times for
 * the same booking.
 * @return {boolean} .
 */const makeSelectWasPixelFiringFunctionCalledForConversion=()=>createSelector(makeSelectCurrentGuidBooking(),a=>a,(a,b)=>{if(!a)return !1;const c={pixelGenericEventType:PIXEL_GENERIC_EVENT_TYPES.CONVERSION,idUnique:a};return makeSelectWasPixelFiringFunctionCalled$1(c)(b)});var makeSelectWasPixelFiringFunctionCalledForConversion$1 = makeSelectWasPixelFiringFunctionCalledForConversion;

/**
 * Dispatches the `canFireTrackingScriptsPageViewEventsInvoked` (used by various
 * pixels) when
 * the asynchronous `APIs` that we load for every page, are all loaded for the
 * current page. This way, the pixels can do their thing when all the required
 * data is already available and loaded.
 *
 * Expectation #1) `canFireTrackingScriptsPageViewEventsInvoked` in the
 * `redux` developer tools.
 * Expectation #2) The triggered event should be triggered once per page, use `redux`
 * developer tools and verify that `canFireTrackingScriptsPageViewEventsInvoked`
 * is triggered once. Also verify using `Google` analytics dashboard or
 * "Tag assistant" website. Open "Tag Assistant" (@see {@link https://tagassistant.google.com}).
 * Then enter the current testing domain @example `https://test.daybreakhotels.com`.
 * Then go to `Google` analytics `GA4` dashboard.
 * @see {@link https://analytics.google.com/analytics/web/?authuser=1#/m/p326658850/debugview/overview?params=_u..nav%3Dmaui}
 * Go to "Configure", then go to "DebugView", then choose the debug device
 * and you will be able to view the triggered events.
 *
 * First testing procedure:
 * 1. Load a page from server side rendering in: `/IT/en-GB`.
 * Expect #1.
 * 2. Using the menu on top, switch to: `/IT/it-IT`.
 * Expect #1.
 * 3. Using the menu on top, switch back to `/IT/en-GB`.
 * Expect #1.
 * 4. Using the menu on top, switch to `/GB/en-GB`.
 * Expect #1.
 * 5. Using the menu on top, open the sign in modal.
 * Expect #1.
 * 6. Close the sign in modal without signing in.
 * Expect #1.
 * 7. Using the menu on top, open the sign in modal again.
 * Expect #1.
 * 8. The sign in modal closes automatically after signing in.
 * Expect #1.
 * 9. Using the menu on top, sign out.
 * Expect #1.
 *
 * Second testing procedure:
 * 1. Load a page from server side rendering in: `/IT/it-IT`.
 * Expect #1.
 * 2. Search, then you go to `IT/it-IT/22/search...`.
 * Expect #1.
 * 3. Change language to english `IT/en-GB`.
 * Expect #1.
 * 4. Search again, then you go to `IT/en-GB/22/search...`.
 * Expect #1.
 * 5. Change country to Germany, `DE/de-DE`.
 * Expect #1.
 * 6. Go back to `IT/en-GB/22/search...`.
 * Expect #1.
 * 7. Go back again to `IT/en-GB`.
 * Expect #1.
 * 8. Go forward to `IT/en-GB/22/search...`.
 * Expect #1.
 */const useCanFirePageViewEventDispatcher=()=>{const a=useMakeSelect(makeSelectTemplateCode),b=useMakeSelect(makeSelectWebpagesLoaded),c=useMakeSelect(makeSelectIsTrackingScriptsManagerEnabled$1),d=useMakeSelect(makeSelectCountryAndLocale),e=useMakeSelect(makeSelectCurrentCountry),f=useMakeSelect(makeSelectCurrentWebpageRouteTemplateCode),g=useCanFirePageViewEvent(),h=useMemo(()=>f===PAGE_TYPES.TopMenuHashPage?f:a,[f,a]),i=!!(b&&d&&c&&e);// When the user changes the country and, or language using the switcher or by
// tapping a link that points to another "site" (different country and, or
// language), the `LanguageProvider` container unmounts all its child components
// while waiting for the new "messages" to come from the `API`. We expect this
// component to be unmounted too, so that the following effect is triggered
// in that case too.
useEffect(()=>{i&&g({templateCode:h});},[g,i,h]);};var useCanFirePageViewEventDispatcher$1 = useCanFirePageViewEventDispatcher;

const injectedReducerConfig={key:REDUX_AND_SAGA_KEY,reducer},useBrowserOnlyInjectTrackingScriptsReducer=()=>useBrowserOnlyInjectReducer(injectedReducerConfig);var useBrowserOnlyInjectTrackingScriptsReducer$1 = useBrowserOnlyInjectTrackingScriptsReducer;

/*
 *
 * Saga: `handleTrackingScriptsManagerDisabled`.
 *
 */function*handleTrackingScriptsManagerDisabled(){if(-1===location.href.indexOf("dbh_testonly_disable_pixels=true")){const a=location.search.length?"&":"?";location.assign(""+location.href+a+"dbh_testonly_disable_pixels=true");}else location.reload();}

export { AWIN_ADVERTISER_ID, CAN_FIRE_PAGE_VIEW_TRACKING_EVENT, CONVERSION_PIXELS_CAN_BE_FIRED, DATA_LAYER_EVENTS, GTAG_RELATED_PIXELS, GTAG_SPEED_SAMPLE_RATE, PIXELS_FIRING_FUNCTIONS_CALLED, PIXEL_GENERIC_EVENT_TYPES, RECORDING_HTML_MASK_ATTRIBUTES, RECORDING_HTML_UN_MASK_ATTRIBUTES, REDUX_AND_SAGA_KEY, TRACKING_SCRIPTS_INJECTED, TRACKING_SCRIPTS_MANAGER_ACTIVE_FOR_BOTS, TRACKING_SCRIPTS_MANAGER_DISABLED, conversionPixelsCanBeFired, createLoggers$1 as createLoggers, getHtmlPrivacySensitiveAttributes$1 as getHtmlPrivacySensitiveAttributes, handleTrackingScriptsManagerDisabled, initialState, loadPixelAndRemoveItAfter$1 as loadPixelAndRemoveItAfter, makeSelectHotelTypeData$1 as makeSelectHotelTypeData, makeSelectIsTrackingScriptsManagerEnabled$1 as makeSelectIsTrackingScriptsManagerEnabled, makeSelectWasPixelFiringFunctionCalledForConversion$1 as makeSelectWasPixelFiringFunctionCalledForConversion, pixelsFiringFunctionsCalledForConversion, trackingScriptsInjected, reducer as trackingScriptsManagerReducer, useBrowserOnlyInjectTrackingScriptsReducer$1 as useBrowserOnlyInjectTrackingScriptsReducer, useCanFirePageViewEventDispatcher$1 as useCanFirePageViewEventDispatcher, useTrackingScriptsManagerDisabled };
